<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "../.routify/routes";
</script>

<style  global>:global(html), :global(body) {
	position: relative;
	width: 100%;
	height: 100%;
}

:global(body) {
	color: #333;
	margin: 0;
	padding: 8px;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

:global(a) {
	color: rgb(0,100,200);
	text-decoration: none;
}

:global(a:hover) {
	text-decoration: underline;
}

:global(a:visited) {
	color: rgb(0,80,160);
}

:global(label) {
	display: block;
}

:global(input), :global(button), :global(select), :global(textarea) {
	font-family: inherit;
	font-size: inherit;
	padding: 0.4em;
	margin: 0 0 0.5em 0;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 2px;
}

:global(input:disabled) {
	color: #ccc;
}

:global(input[type="range"]) {
	height: 0;
}

:global(button) {
	color: #333;
	background-color: #f4f4f4;
	outline: none;
}

:global(button:disabled) {
	color: #999;
}

:global(button:not(:disabled):active) {
	background-color: #ddd;
}

:global(button:focus) {
	border-color: #666;
}</style>

<Router {routes} />